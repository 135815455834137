import React from "react";
import '../assets/style/Header.css';
import logo_sci from '../assets/img/logo_sci.svg';
import Positive_Parenting from '../assets/img/Parenting_Tips1.png';

const Header = () => {
    return (
        <div className="header-container">
            <img src={Positive_Parenting} alt="Logo_Save_the_Children" className="logo-header-file"/>
            <div className="header-container-text">
                <h6>ParentingTips</h6>
                <p>គន្លឹះការចិញ្ចឹមកូន</p>
            </div>
        </div>
    );
}

export default Header;